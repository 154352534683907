// core
import React, { useEffect, useState } from 'react';
import { Button, Radio, RadioChangeEvent } from 'antd';
import { Area } from '@ant-design/plots';
import dayjs from 'dayjs';
import { formatPrice } from '../../../../utils/commons';
import { toast } from 'react-toastify';

//locales
import { useTranslation } from 'react-i18next';

//hooks
import {
  useMutationBuyBargain,
  useQueryFetchPriceHistory,
} from '../../../../hooks/api';
// store
import { useAppStore, useCartStore } from 'store';

// types
import { IPropsType } from './types';
import {
  EButtonSizeType,
  EButtonTypeType,
  ERadioButtonSizeType,
  ERadioButtonStyleType,
} from 'types/units';
// components
import { EmptyCard, OfferTradeModalCard } from 'components/features';
// styles
import './index.scss';

// data
import { generalChartConfig, generalTooltipConfig } from 'config/charts';
import { ECardSizeType } from '../../Cards/EmptyCard/types';
import { dateTypes } from './data';
import { EGameType } from 'types/models';

const OfferTradeModal = ({ modalId }: IPropsType) => {
  const { t } = useTranslation();
  const { cartProduct } = useCartStore();
  const { sModalClose } = useAppStore();
  const [product, setProduct] = useState<any>(null);
  const [staticProductPrice, setStaticProductPrice] = useState<any>(null);
  const [chartData, setChartData] = useState([]);
  const [summary, setSummary] = useState<number>(0);
  const [chartDateType, setChartDateType] = useState(dateTypes[0].range);
  const [filterData, setFilterData] = useState<any>(null);
  const [enabledFetchChart, setEnabledFetchChart] = useState<any>(false);

  const {
    data: sendBargainData,
    isLoading: isLoadingBargainData,
    mutate: mutateSendBargain,
  } = useMutationBuyBargain();

  const { data: rawChartData, isLoading: isLoadingRawChart } =
    useQueryFetchPriceHistory(filterData, enabledFetchChart);

  useEffect(() => {
    if (!isLoadingBargainData && sendBargainData) {
      toast.success(t('toastSuccessBargain'));
      sModalClose(modalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingBargainData, sendBargainData]);

  const { xAxis, yAxis, ...chartConfig } = generalChartConfig;

  const config = {
    data: chartData,
    xField: 'Date',
    yField: 'scales',
    ...chartConfig,
    xAxis: { ...xAxis, tickCount: 7 },
    yAxis: { ...yAxis, tickCount: 6 },
    animation: false,
    tooltip: {
      ...generalTooltipConfig,
      customContent: (title, items) => {
        if (items && items.length) {
          const { data } = items[0];
          return (
            <div className="g2-tooltip__content">
              <div className="g2-tooltip__date">
                {dayjs(title).format('MMMM D, HH:mm')}
              </div>
              <div className="g2-tooltip__price">$ {data.scales}</div>
              <div className="g2-tooltip__count">
                {t('sold')} <span>{data.count}</span>
              </div>
            </div>
          );
        } else {
          return null;
        }
      },
    },
  };

  useEffect(() => {
    setProduct(cartProduct);
    setStaticProductPrice(cartProduct.price);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (product) {
      setFilterData({
        ...dateTypes[0].range,
        firstName: product?.marketName.first,
        secondName: product?.marketName.second,
        provider: cartProduct?.provider,
        extra: product?.exterior,
      });
      setEnabledFetchChart(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    setSummary(product ? product.sale * product.price : 0);
  }, [product, chartData.length]);

  useEffect(() => {
    if (rawChartData && !isLoadingRawChart) {
      setChartData(rawChartData);
    }
  }, [isLoadingRawChart, rawChartData]);

  const onChangeChartDateType = ({ target: { value } }: RadioChangeEvent) => {
    setChartDateType(value);
    if (typeof value !== 'string') {
      setFilterData({
        ...value,
        firstName: product?.marketName.first,
        secondName: product?.marketName.second,
        provider: cartProduct?.provider,
        extra: product?.exterior,
      });
    } else {
      setFilterData({
        firstName: product?.marketName.first,
        secondName: product?.marketName.second,
        provider: cartProduct?.provider,
        extra: product?.exterior,
      });
    }
  };

  const onChangePrice = value => {
    let staticWithPercentage = staticProductPrice - staticProductPrice * 0.01;
    console.log('asd', staticWithPercentage);
    let insertValue = 0;
    if (product.minAutoBuyPrice > 0.02 || value > 0.02) {
      if (value > product.minAutoBuyPrice && value <= staticWithPercentage) {
        insertValue = value;
      }
    }
    setProduct(prevData => {
      return { ...prevData, price: insertValue };
    });
  };

  const onSendTrade = () => {
    mutateSendBargain({ itemId: product.sku, price: Number(product.price) });
  };

  return (
    <div className="offer-trade-modal">
      <div className="offer-trade-modal__content">
        <div className="offer-trade-modal__title">{t('tradeRequest')}</div>
        {product && (
          <>
            <OfferTradeModalCard
              data={product}
              price={product.price}
              defaultPrice={product.price}
              onPriceChange={onChangePrice}
            />
            <div className="offer-trade-modal__chart-filter">
              <Radio.Group
                buttonStyle={ERadioButtonStyleType.Solid}
                value={chartDateType}
                size={ERadioButtonSizeType.Middle}
                onChange={onChangeChartDateType}
              >
                {dateTypes?.length &&
                  dateTypes.map(item => {
                    return (
                      <Radio.Button value={item.range} key={item.value}>
                        {t(item.value)}
                      </Radio.Button>
                    );
                  })}
              </Radio.Group>
            </div>
            <div className="offer-trade-modal__chart">
              {config?.data?.length ? (
                <>
                  {/*@ts-ignore*/}
                  <Area {...config} />
                </>
              ) : (
                <EmptyCard
                  size={ECardSizeType.Minimal}
                  title={t('emptyChartTitle')}
                  description={t('emptyChartDesc')}
                />
              )}
            </div>
          </>
        )}
      </div>

      <div className="offer-trade-modal__footer">
        <div className="offer-trade-modal__summary">
          <div className="offer-trade-modal__summary-title">
            {t('modals.offerTrade.requestFor')}{' '}
            <span>
              $ {product?.price ? formatPrice(Number(product.price)) : 0}
            </span>
          </div>
          <div className="offer-trade-modal__summary-text">
            {t('withoutCommission')}
          </div>
        </div>
        <Button
          type={EButtonTypeType.Primary}
          size={EButtonSizeType.Large}
          className="new-trade-modal__btn"
          disabled={isLoadingBargainData || !product?.price}
          onClick={() => {
            onSendTrade();
          }}
        >
          {t('sendRequest')}
        </Button>
      </div>
    </div>
  );
};

export { OfferTradeModal };
